<template>
  <div class="">
    <!--本页切换列表-->
    <div>
      <div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
        <div class="tableConTable">
          <div class="tableConTop">
            <el-row>
              <el-col :span="8" class="tableConTopLeft">
                <h3>Fedex<span class="tct_tit">{{$t('i18nn_785ee8efb6d09b2a')}}</span>
									<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
										<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
									</el-tooltip>
								</h3>
								
              </el-col>
              <el-col :span="16" class="tableConTopRig">
                
                <el-button @click="toExSheetView($event, null)" type="primary" size="mini" icon="el-icon-refresh">{{$t('i18nn_36edafcc55cf50f6')}}</el-button>
                
                <el-button @click="revocationAction($event, null)" type="success" size="small" icon="el-icon-document-delete">{{$t('i18nn_4ca88dd2e7071844')}}</el-button>

                <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
              </el-col>
            </el-row>
          </div>

          <div>
            <div class="filterCon" style="">
							<div class="filterBarList">
									<!-- <el-radio-group v-model="filterData.labelStatus" size="medium" @change="initData()">
									  <el-radio-button border v-for="item in selectOption.labelStatusList" :key="item.value" :label="item.value">
											{{item.label}}
										</el-radio-button>
									</el-radio-group> -->
								<!-- <el-tabs  v-model="filterData.labelStatus" @tab-click="initData">
								  <el-tab-pane :label="item.label" :name="item.value" v-for="item in selectOption.labelStatusList" :key="item.value"></el-tab-pane>
								</el-tabs> -->
								<el-menu :default-active="filterData.labelStatus" mode="horizontal" @select="handleSelectStatus">
								  <el-menu-item :index="item.value" v-for="item in selectOption.labelStatusList" :key="item.value">{{item.label}}</el-menu-item>
								</el-menu>
							</div>
              <!-- <el-row type="flex" justify="end"> -->
                <!-- <el-col :span="24"> -->
                  <ul class="filterConList">
                    <li>
                      <span>{{ $t('hytxs0000060') }}</span>
                      <cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
                    </li>
										<li>
											<span>{{$t('c944a6686d996ab3')}}</span>
											<whNoSelect @changeData="changWhNo"></whNoSelect>
										</li>
										<li>
											<span>{{$t('i18nn_5b7f75f3443354a2')}}</span>
											<el-date-picker
												v-model="filterData.daterange"
												size="small"
												type="daterange"
												align="right"
												:clearable="false"
												unlink-panels
												range-separator="-"
												start-placeholder="start date"
												end-placeholder="end date"
												:picker-options="pickerOptions"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd"
												@change="initData()"
											></el-date-picker>
										</li>
                    <!-- <li>
                      <span>{{$t('i18nn_06dd7cb65641390b')}}</span>
                      <el-select filterable clearable v-model="filterData.labelReady" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
                        <el-option v-for="item in selectOption.labelReadyList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                      </el-select>
                    </li> -->
									</ul>
									<ul class="filterConList">
										<!-- <li>
										  <span>{{$t('i18nn_90429ac39f4f4634')}}</span>
										  <el-select filterable clearable v-model="filterData.labelStatus" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
										    <el-option v-for="item in selectOption.labelStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
										  </el-select>
										</li> -->
										<li>
										  <span>{{$t('i18nn_06dd7cb65641390b')}}</span>
										  <el-select filterable clearable v-model="filterData.labelReady" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
										    <el-option v-for="item in selectOption.labelReadyList" :key="item.value" :label="item.label" :value="item.value"></el-option>
										  </el-select>
										</li>
                    <li>
                      <span>{{$t('i18nn_abf9f4b8c93d641e')}}</span>
                      <el-input
                        type="text"
                        v-model="filterData.orderNumber"
                        size="small"
                        clearable
                        @keyup.enter.native="initData()"
                        maxlength="50"
                        :placeholder="$t('hytxs0000001')"
                        style="width: 180px;"
                      />
                    </li>
                    <li>
                      <span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
                      <el-input
                        type="text"
                        v-model="filterData.keyword"
                        size="small"
                        clearable
                        @keyup.enter.native="initData()"
                        maxlength="50"
                        :placeholder="$t('custom_enterfuzzysearch')"
                        style="width: 180px;"
                      />
                    </li>
                    <li>
                      <el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
                    </li>
                  </ul>
                <!-- </el-col> -->
              <!-- </el-row> -->
            </div>
          </div>
					
          <el-table
            v-loading="loading"
            id="out-table2"
            ref="multipleTable"
            :data="tableData"
            stripe
            :border="true"
            :height="$store.state.tableMaxHeight7"
            @selection-change="handleSelectionChange"
            @row-click="handleCurrentChange"
            style="width: 100%"
            size="small"
          >
            <el-table-column type="expand" fixed="left">
              <template slot-scope="scope">
								<div style="margin-left: 100px;">
									<div v-if="scope.row && scope.row.labelRequestJson">
									  <el-table :data="[scope.row.labelRequestJson]" :border="true" style="width: 100%;" size="small">
									    <el-table-column type="index" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>
									    <el-table-column prop="itemList" label="SKU">
												<template slot-scope="scope2">
												  <div v-if="scope2.row.itemList && scope2.row.itemList[0]">{{ scope2.row.itemList[0].sku }}</div>
												</template>
											</el-table-column>
											<el-table-column prop="itemList" :label="$t('f9db93b87e08763b')">
												<template slot-scope="scope2">
												  <div v-if="scope2.row.itemList && scope2.row.itemList[0]">{{ scope2.row.itemList[0].quantity }}</div>
												</template>
											</el-table-column>
									    <el-table-column prop="parcels" :label="$t('0e60303b30d5d4b4')" width="100">
									      <template slot-scope="scope2">
									        <div v-if="scope2.row.parcels && scope2.row.parcels[0]">{{ scope2.row.parcels[0].length }}</div>
									      </template>
									    </el-table-column>
											<el-table-column prop="mySkuDto" :label="$t('6cd31871f8528dd5')" width="100">
											  <template slot-scope="scope2">
											     <div v-if="scope2.row.parcels && scope2.row.parcels[0]">{{ scope2.row.parcels[0].width }}</div>
											  </template>
											</el-table-column>
											<el-table-column prop="mySkuDto" :label="$t('93c9ea4a0e52c641')" width="100">
											  <template slot-scope="scope2">
											     <div v-if="scope2.row.parcels && scope2.row.parcels[0]">{{ scope2.row.parcels[0].height }}</div>
											  </template>
											</el-table-column>
											<el-table-column prop="mySkuDto" :label="$t('i18nn_8004917a89ed807b')" width="100">
											  <template slot-scope="scope2">
											     <div v-if="scope2.row.parcels && scope2.row.parcels[0]">{{ scope2.row.parcels[0].weight }}</div>
											  </template>
											</el-table-column>
											<el-table-column prop="mySkuDto" :label="$t('b6bf0a07fe26f74f')" width="100">
											  <template slot-scope="scope2">
											    <div>
														<span v-if="0===scope2.row.weightUnitType">lb/in</span>
														<span v-else-if="1===scope2.row.weightUnitType">kg/cm</span>
														<span v-else></span>
													</div>
											  </template>
											</el-table-column>
											<el-table-column prop="shipFrom" :label="$t('i18nn_f13b5a3276612c1e')" width="400">
											  <template slot-scope="scope2">
											     <div v-if="scope2.row.shipFrom">
														{{scope2.row.shipFrom.name}},
														{{scope2.row.shipFrom.company}},
														{{scope2.row.shipFrom.address1}},
														{{scope2.row.shipFrom.address2}},
														{{scope2.row.shipFrom.city}},
														{{scope2.row.shipFrom.state}},
														{{scope2.row.shipFrom.country}},
														{{scope2.row.shipFrom.postalCode}},
														{{scope2.row.shipFrom.phoneNumber}},
														<!-- {{scope2.row.shipFrom.mail}}, -->
													 </div>
											  </template>
											</el-table-column>
											<el-table-column prop="shipTo" :label="$t('i18nn_e366dc49c78c8e3d')" width="400">
											  <template slot-scope="scope2">
											     <div v-if="scope2.row.shipTo">
													 <!-- {{ scope2.row.shipTo }} -->
													 {{scope2.row.shipTo.name}},
													 {{scope2.row.shipTo.company}},
													 {{scope2.row.shipTo.address1}},
													 {{scope2.row.shipTo.address2}},
													 {{scope2.row.shipTo.city}},
													 {{scope2.row.shipTo.state}},
													 {{scope2.row.shipTo.country}},
													 {{scope2.row.shipTo.postalCode}},
													 {{scope2.row.shipTo.phoneNumber}},
													 </div>
											  </template>
											</el-table-column>
											<el-table-column :label="$t('93f5ca01b006206c')" width="300px" fixed="right"></el-table-column>
									  </el-table>
									</div>
									<div v-else>{{$t('67a39834582ad99a')}}</div>
								</div>
                
              </template>
            </el-table-column>
            <el-table-column type="selection" fixed="left" align="center" width="50"></el-table-column>
            <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>
            <!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

            <!-- "id":"",
                "cusNo":$t('i18nn_64fed2851f79d875'),
                "packageNo":$t('i18nn_7b27c6b2a3f93412'),
                "goodsSku":$t('i18nn_e0c16ac094cc2bce'),
                "goodsImg":$t('i18nn_877c5a0e44a0eb07'),
                "goodsName":$t('i18nn_9eeccc6d6f1ff32f'),
                "weightUnit":$t('i18nn_8e01866868930d09'),
                "goodsWeight":$t('i18nn_ad4b0047e16d89ae'),
                "volumeUnit":$t('i18nn_57936a4767ded7f5'),
                "goodsLength":$t('0e60303b30d5d4b4'),
                "goodsWidth":$t('6cd31871f8528dd5'),
                "goodsHeight":$t('93c9ea4a0e52c641'),
                "goodsCount":$t('hytxs0000008') -->

            <!-- <el-table-column :label="$t('i18nn_8bdf7efaeff69d77')"> -->

            <!-- <el-table-column prop="statusName" :label="$t('6cdece641436d7ab')">
              <template slot-scope="scope">
                
                <el-tag type="" v-if="'0' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
                <el-tag type="success" v-else-if="'10' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
                <el-tag type="warning" v-else-if="'20' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
                <el-tag type="danger" v-else-if="'30' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
                <el-tag type="success" v-else-if="'35' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
                <el-tag type="warning" v-else-if="'40' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
                <el-tag type="danger" v-else-if="'50' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
                <el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
              </template>
            </el-table-column> -->
						<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
						<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')">
							
						</el-table-column>

            <el-table-column prop="labelReadyName" :label="$t('i18nn_06dd7cb65641390b')"></el-table-column>
            <el-table-column prop="labelStatusName" :label="$t('i18nn_90429ac39f4f4634')"></el-table-column>

            <el-table-column prop="labelOrderNum" :label="$t('i18nn_48ebfc4319cbac59')"></el-table-column>

            <el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip></el-table-column>

            <el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')"></el-table-column>
						
						<el-table-column prop="carrierServiceLevel" :label="$t('i18nn_61fc9e07fff97930')"></el-table-column>
						
            
            <el-table-column prop="tranckingNo" :label="$t('i18nn_4531476fa35570f0')"></el-table-column>
						
						<el-table-column prop="shippingPrice" :label="$t('i18nn_ab6994d16b9b4366')"></el-table-column>
						
						<el-table-column prop="serviceFee" :label="$t('i18nn_ed66f1feaedb8f00')"></el-table-column>
						
						<el-table-column prop="serviceFee" :label="$t('i18nn_3c4a40675f59cd1c')">
							<template slot-scope="scope">
							  <div v-if="scope.row && scope.row.shippingPrice && scope.row.serviceFee">
									{{parseFloat(scope.row.shippingPrice)+parseFloat(scope.row.serviceFee)}}
								</div>
							</template>
						</el-table-column>
           
            <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
							<template slot-scope="scope">
								<el-popover placement="top"
							    trigger="hover"
							    ><div class="pre-text">{{ scope.row.remark }}</div>
							    <span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
							  </el-popover>
							</template>
						</el-table-column>
						
						<el-table-column prop="labelTime" :label="$t('i18nn_5f6b842a6ff748ab')"></el-table-column>
						<el-table-column prop="createTime" :label="$t('i18nn_5b7f75f3443354a2')"></el-table-column>

            
            <el-table-column :label="$t('93f5ca01b006206c')" width="300px" fixed="right">
              <template slot-scope="scope">
                <!-- <div v-if="isSel"> -->
                <div style="margin-bottom: 5px;">
                  <!-- <el-button @click="toDet($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-view">{{$t('4c0e3495d9042ebf')}}</el-button> -->
                  <!-- <el-button @click="toPrinterView($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-printer">{{$t('i18nn_36edafcc55cf50f6')}}</el-button> -->
                  <el-button @click="toExSheetView($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-refresh">{{$t('i18nn_36edafcc55cf50f6')}}</el-button>
									
									<!-- //进行中且出单未成功 -->
									<!-- labelReady:"",
									labelStatus:"",  v-if="'0'==scope.row.labelReady && '10'==scope.row.labelStatus"-->
									<el-button  v-if="'0'==scope.row.labelReady && '10'==scope.row.labelStatus" @click="editServiceLevelAction($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t('i18nn_9e724924dd8b83dc')}}</el-button>
									                
								</div>
                <div style="margin-bottom: 5px;">
                  <!-- <el-button @click="cancelDeliveryAction($event, scope.row, scope.$index)" type="danger" size="mini" icon="el-icon-close">{{ $t('hytxs0000064') }}</el-button> -->
                  <el-button @click="cancelAction($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-close">{{$t('i18nn_1f90c9eb506ddf15')}}</el-button>

                  <el-button @click="revocationAction($event, scope.row, scope.$index)" type="danger" size="mini" icon="el-icon-document-delete">{{$t('i18nn_4ca88dd2e7071844')}}</el-button>
                </div>

              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
      </div>
    </div>

    <!-- 明细 -->
    <el-dialog :title="$t('i18nn_76cd201aeb72dba2')" :close-on-click-modal="false" append-to-body :visible.sync="dialogPalletDet" width="1200px" top="0px">
      <div><!-- <WhInPalletData :sendId="PalletDetId"></WhInPalletData> --></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogPalletDet = false">{{ $t('4e9fc68608c60999') }}</el-button>
      </div>
    </el-dialog>

    <!--编辑-->
    <el-dialog :title="$t('6267f3aedf895209')" :close-on-click-modal="false" append-to-body :visible.sync="dialogEditData" width="800px">
      <div>
        <el-form ref="EditData" :model="EditData" label-width="100px" v-loading="loading" :element-loading-text="$t('44bd089554515c4d')">
          <el-form-item :label="$t('i18nn_61fc9e07fff97930')">
						<el-select filterable clearable v-model="EditData.carrierServiceLevel" :placeholder="$t('2ad108ab2c560530')">
						  <el-option v-for="item in selectOption.wh_fedex_carrier_service_fevel" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
				<span class="sel_option_code" style="">{{ item.code }}</span>
			</div></el-option>
						</el-select>
					</el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogEditData = false">{{ $t('4e9fc68608c60999') }}</el-button>
        <el-button type="primary" @click="submitEditDataForm()">{{ $t('ad4345dbaabe1479') }}</el-button>
      </div>
    </el-dialog>

    
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination" :excelName="excelName" :excelOption="excelOption"
		:expHttpUrl="$urlConfig.WhPrtOrderLabelInfoPageList" :expHttpFilter="pageFilterData()" :hasFormatter="true" :formatterData="formatterData"></whExcelCustom>
		
    <!--  附件查看 -->
    <!-- <whFileView :openTime="FileUploadOpenTime" :fileList="fileDataList"></whFileView> -->
  </div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
// import WhInPalletData from './WhInPalletData.vue';
// import WhInPalletDataDetList from './WhInPalletDataDetList.vue';
// import whFileView from './components/whFileView.vue';
import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
// import barcode from '@/components/Common/barcode.vue';
import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';

import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';

export default {

  components: {
    // WhInPalletData,
    // whFileView,
    // barcode,
    cusSelFuzzy,
		whExcelCustom,
		whNoSelect
    // WhInPalletDataDetList
  },
  data() {
    return {
			//excel导出
			excelOpenTime:'',
			excelHead:[],
			expExcelData:[],
			excelName:"",
			excelOption:{},
			
			pickerOptions: this.$PickerDate.pickerOptions_2(),
			
      multipleSelection: [],
			
      // expands:[],
      //上传图片
      // dialogImgUploadVisible: false,
      // imgId: this.$store.getters.getUserInfo.id,
      // openRowData: {},
      // openRowIndex: {},

      //导入excel
      // dialogUploadVisible: false,
      // loadingExcel: false,
      // fileExcel: '',
      // excelData: [],
      // excelFileName: '',

      //图片放大
      // dialogImgVisible: false,
      // imgUrlBigShow: '',

      //打开入库选择框
      // dialogSelVisible: false,

      //打托明细
      dialogPalletDet: false,
      PalletDetId: '',

      // FileUploadOpenTime: '',
      // fileDataList: [],

      //编辑
      dialogEditData: false,
      EditData: {},

      //打单费率
      // PriterOrderRate: {},
      // loading_rate_load: false,

      loading_load: false,

      loading_det_load: false,

      //表格数据
      //loading,表格数据
      loading: false,
      //表格数据
      tableData: [],
      //分页数据
      pagination: this.$Utils.defaultPagination(),
      //详情数据
      // loading_det: false,
      // detData: [],
      // dialogDetVisible: false,
      // form: {
      //   id: null,

      //   plName: '', //拣货单名称',
      //   plRecordDtos: [
      //     {
      //       id: '',
      //       putWhNo: '', //入库编号',
      //       goodsSku: '', //$t('i18nn_31f887c1ada79a99'),
      //       goodsName: '', //$t('i18nn_9eeccc6d6f1ff32f'),
      //       goodsImg: '', //$t('2674282277c3714e'),
      //       plCount: '', //'1', //$t('i18nn_0bd7d983fe70ea56'),
      //       plVol: '', //'2', //$t('i18nn_6d8cbf8c49d6a7e6'),
      //       plWeight: '', //'3', //$t('i18nn_3e1652df125209de'),
      //       plPlaceNo: '' //'4' //$t('i18nn_9a42179fb9922dc1')
      //     }
      //   ]

      //   // "confName": "",
      //   // "confId": "", //新增时 为空  编辑时必传
      //   // "feeType": "", //费用类型

      //   // "divConfList": [{
      //   //   "benefitedParty": "", //受益方
      //   //   "partnerType": "", //合伙人类型
      //   //   "divWays": "", //分配方式
      //   //   "divValue": "" //分配值
      //   // }]
      //   // effectiveDate:"",//生效时间
      // },
      // loading_det: false,
      // form: {
      //     "id":null, //"1212",
      //     "putTitle":'', //$t('7431e76029678ec7'),
      //     "putWhDate":'', //$t('i18nn_4647ba3fc60f0151'),
      //     "goodsRecordsDtoList":[
      //         {
      //             "id":'', //"",
      //             "cusNo":'', //$t('i18nn_64fed2851f79d875'),
      //             "packageNo":'', //$t('i18nn_7b27c6b2a3f93412'),
      //             "goodsSku":'', //$t('i18nn_e0c16ac094cc2bce'),
      //             "goodsImg":'', //$t('i18nn_877c5a0e44a0eb07'),
      //             "goodsName":'', //$t('i18nn_9eeccc6d6f1ff32f'),
      //             "weightUnit":'1', //$t('i18nn_8e01866868930d09'),
      //             "goodsWeight":'', //$t('i18nn_ad4b0047e16d89ae'),
      //             goodsAllWeight:'', //$t('hytxs0000009'),
      //             "volumeUnit":'1', //$t('i18nn_57936a4767ded7f5'),
      //             "goodsLength":'', //$t('0e60303b30d5d4b4'),
      //             "goodsWidth":'', //$t('6cd31871f8528dd5'),
      //             "goodsHeight":'', //$t('93c9ea4a0e52c641'),
      //             goodsAllVol:'', //$t('hytxs0000010'),
      //             "goodsCount":'', //$t('hytxs0000008')
      //         }
      //     ]
      // },
      // invalidDateForm: {
      //   "confId": null,
      //   "invalidDate": "", //失效日期
      //   effectiveDate: "", //生效时间
      //   confName: "", //仅做显示
      // },

      selectOption: {
        wh_weight_unit: [],
        wh_vol_unit: [],
				wh_fedex_carrier_service_fevel:[],
        labelReadyList: [
          {
            value: '',
            label: this.$t('16853bda54120bf1')
          },
          {
            value: '0',
            label: this.$t('i18nn_b44a4a27cac7433e')
          },
          {
            value: '1',
            label: this.$t('i18nn_27372599992ff988')
          },
        ],
				labelStatusList:[
          {
            value: '',
            label: this.$t('16853bda54120bf1')
          },
          {
            value: '10',
            label: this.$t('i18nn_cce168301447d1ba')
          },
					{
					  value: '20',
					  label: this.$t('i18nn_a0acfaee54f06458')
					},
					{
					  value: '70',
					  label: this.$t('i18nn_bcef26d98d690aba')
					},
					{
					  value: '90',
					  label: this.$t('i18nn_4ca88dd2e7071844')
					},
        ]
      },
      //查询，排序方式
      filterData: {
        orderBy: 'id_', //排序字段
        sortAsc: 'desc', //desc降序，asc升序
				
				daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()+3600 * 1000 * 24)],
        // exprStatus: '',
				whNo:"",
        orderNumber: '',
        userId: '',
        commitDate: '',
        keyword:"",
				labelReady:"",
        labelStatus:"",
      }
    };
  },
  // watch: {
  //   $route(to, from) {
		// 	if('PriterOrderDetList'==to.name){
		// 		if (this.$route.query && this.$route.query.state) {
		// 			this.filterData.exprStatus = this.$route.query.state;
		// 		}
		// 		this.initData();
		// 	}
  //   }
  // },
  activated() {
    // if (this.$route.query && this.$route.query.state) {
    //   this.filterData.exprStatus = this.$route.query.state;
    // }
    // this.initData();
		this.initData();
  },
  //创建时
  created() {
    // if (this.$route.query && this.$route.query.state) {
    //   this.filterData.exprStatus = this.$route.query.state;
    // }
    
  },
  //编译挂载前
  mounted() {
		
    // this.getDicData();
		//数据字典
		getDicData(['wh_fedex_carrier_service_fevel'],
			(data)=>{
				this.selectOption.wh_fedex_carrier_service_fevel = data['wh_fedex_carrier_service_fevel'];
		});
  },
  methods: {
    initData() {
      this.pagination.current_page = 1;
      // this.currentSel = {};
      this.multipleSelection = [];
      this.getPageData();
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout();
      });
      // this.getDicData();
    },
		handleSelectStatus(key, keyPath) {
		  // console.log(key, keyPath);
			this.filterData.labelStatus = key;
			this.initData();
		},
		//导出 excel
		exportExcel() {
			let columns = [
				{
					title:this.$t('5acbec83efb27445'),
					key: 'whNo'
				},
				{
					title:this.$t('i18nn_abf9f4b8c93d641e'),
					key: 'orderNumber'
				},
				{
					title: this.$t('i18nn_06dd7cb65641390b'),
					key: 'labelReadyName'
				},
				{
					title: this.$t('i18nn_90429ac39f4f4634'),
					key: 'labelStatusName'
				},
				{
					title: this.$t('i18nn_48ebfc4319cbac59'),
					key: 'labelOrderNum'
				},
			
				{
					title: this.$t('hytxs0000060'),
					key: 'cusName'
				},
				
				{
					title: this.$t('i18nn_b0c50b6ff9674173'),
					key: 'carrier'
				},
				{
					title: this.$t('i18nn_61fc9e07fff97930'),
					key: 'carrierServiceLevel'
				},
				{
					title: this.$t('i18nn_4531476fa35570f0'),
					key: 'tranckingNo'
				},
				
				{
					title: this.$t('i18nn_ab6994d16b9b4366'),
					key: 'shippingPrice'
				},
				{
					title: this.$t('i18nn_ed66f1feaedb8f00'),
					key: 'serviceFee'
				},
				{
					title: this.$t('i18nn_3c4a40675f59cd1c'),
					key: 'serviceFeeCount',
					types: 'custom',
					formatter: function(row) {
						if(!!row.shippingPrice && row.serviceFee){
							return parseFloat(row.shippingPrice)+parseFloat(row.serviceFee);
						} else {
							return '';
						}
						
					}
				},
				{
					title: this.$t('15b3627faddccb1d'),
					key: 'remark'
				},
				{
					title: this.$t('i18nn_5f6b842a6ff748ab'),
					key: 'labelTime'
				},
				{
					title: this.$t('i18nn_5b7f75f3443354a2'),
					key: 'createTime'
				},
				{
					title: 'SKU',
					key: 'sku',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson && row.labelRequestJson.itemList &&row.labelRequestJson.itemList[0]){
							return row.labelRequestJson.itemList[0].sku;
						} else {
							return '';
						}
					}
				},
				{
					title: this.$t('f9db93b87e08763b'),
					key: 'skuQuantity',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson && row.labelRequestJson.itemList &&row.labelRequestJson.itemList[0]){
							return row.labelRequestJson.itemList[0].quantity;
						} else {
							return '';
						}
					}
				},
				{
					title: this.$t('0e60303b30d5d4b4'),
					key: 'length',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson && row.labelRequestJson.parcels && row.labelRequestJson.parcels[0]){
							return row.labelRequestJson.parcels[0].length;
						} else {
							return '';
						}
					}
				},
				{
					title: this.$t('6cd31871f8528dd5'),
					key: 'width',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson && row.labelRequestJson.parcels && row.labelRequestJson.parcels[0]){
							return row.labelRequestJson.parcels[0].width;
						} else {
							return '';
						}
					}
				},
				{
					title: this.$t('93c9ea4a0e52c641'),
					key: 'height',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson && row.labelRequestJson.parcels && row.labelRequestJson.parcels[0]){
							return row.labelRequestJson.parcels[0].height;
						} else {
							return '';
						}
					}
				},
				{
					title: this.$t('i18nn_8004917a89ed807b'),
					key: 'weight',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson && row.labelRequestJson.parcels && row.labelRequestJson.parcels[0]){
							return row.labelRequestJson.parcels[0].weight;
						} else {
							return '';
						}
					}
				},
				{
					title: this.$t('b6bf0a07fe26f74f'),
					key: 'unit',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson){
							if(0===row.labelRequestJson.weightUnitType){
								return 'lb/in';
							} else if(1===row.labelRequestJson.weightUnitType){
								return 'kg/cm';
							} else {
								return '';
							}
						} else {
							return '';
						}
					}
				},
				{
					title: this.$t('i18nn_f13b5a3276612c1e'),
					key: 'shipFrom',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson && row.labelRequestJson.shipFrom){
							let obj = row.labelRequestJson.shipFrom;
							return (obj.name?obj.name:'')+","
							+(obj.company?obj.company:'')+","
							+(obj.address1?obj.address1:'')+","
							+(obj.address2?obj.address2:'')+","
							+(obj.city?obj.city:'')+","
							+(obj.state?obj.state:'')+","
							+(obj.country?obj.country:'')+","
							+(obj.postalCode?obj.postalCode:'')+","
							+(obj.phoneNumber?obj.phoneNumber:'')+",";
						} else {
							return '';
						}
					}
				},
				{
					title: this.$t('i18nn_e366dc49c78c8e3d'),
					key: 'shipTo',
					types: 'custom',
					formatter: function(row) {
						if(row.labelRequestJson && row.labelRequestJson.shipTo){
							let obj = row.labelRequestJson.shipTo;
							return (obj.name?obj.name:'')+","
							+(obj.company?obj.company:'')+","
							+(obj.address1?obj.address1:'')+","
							+(obj.address2?obj.address2:'')+","
							+(obj.city?obj.city:'')+","
							+(obj.state?obj.state:'')+","
							+(obj.country?obj.country:'')+","
							+(obj.postalCode?obj.postalCode:'')+","
							+(obj.phoneNumber?obj.phoneNumber:'')+",";
						} else {
							return '';
						}
					}
				},
			];
			// {{scope2.row.shipFrom.name}},
			// 			{{scope2.row.shipFrom.company}},
			// 			{{scope2.row.shipFrom.address1}},
			// 			{{scope2.row.shipFrom.address2}},
			// 			{{scope2.row.shipFrom.city}},
			// 			{{scope2.row.shipFrom.state}},
			// 			{{scope2.row.shipFrom.country}},
			// 			{{scope2.row.shipFrom.postalCode}},
			// 			{{scope2.row.shipFrom.phoneNumber}},
			// 			<!-- {{scope2.row.shipFrom.mail}}, -->
			// 		 </div>
			//   </template>
			// </el-table-column>
			// <el-table-column prop="mySkuDto" :label="$t('i18nn_e366dc49c78c8e3d')" width="400">
			//   <template slot-scope="scope2">
			//      <div v-if="scope2.row.shipTo">
			// 		 <!-- {{ scope2.row.shipTo }} -->
			// 		 {{scope2.row.shipTo.name}},
			// 		 {{scope2.row.shipTo.company}},
			// 		 {{scope2.row.shipTo.address1}},
			// 		 {{scope2.row.shipTo.address2}},
			// 		 {{scope2.row.shipTo.city}},
			// 		 {{scope2.row.shipTo.state}},
			// 		 {{scope2.row.shipTo.country}},
			// 		 {{scope2.row.shipTo.postalCode}},
			// 		 {{scope2.row.shipTo.phoneNumber}},
			let Data = this.tableData;
		
			// if(!this.filterData.wh_car_tms_type){
			// 	this.$message.warning("请先选择出车方式");
			// 	return;
			// }
			// if(!this.filterData.cusNo){
			// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 	return;
			// }
			if(!this.filterData.daterange || this.filterData.daterange.length<2){
				this.$message.warning(this.$t('i18nn_91e192ea5925de92'));
				return;
			}
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'fedexPrinterOrder';
			this.excelOption = { height: 20 };
			this.excelOpenTime = new Date().getTime();
		
			// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
		},
		
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		
    //打开附件上传
    openUploadFile(event, row) {
      event.stopPropagation();
      // this.isShowFileUpload = true;
      // this.fileRelationId = row.id;
      this.FileUploadOpenTime = new Date().getTime();
      // this.FileUploadOpenTime
      this.fileDataList = row.attachments;
    },

    //打印
    toPrinterView(event, row) {
      event.stopPropagation();
      // this.PrinterOpenTime = new Date().getTime();
      // this.PrinterData = [];
      let parm = [];
      if (row) {
        //     //单条
        parm = [row.id];
      } else {
        //多条
        let dataList = this.multipleSelection;
        if (dataList.length < 1) {
          this.$message.warning(this.$t('7b80e66b535a3732'));
          return;
        }
        // if (dataList.findIndex(item=> '10'!=item.exprStatus)>-1) {
        //   this.$message.warning(this.$t('i18nn_7b23bac6ffe353b9'));
        //   return;
        // }

        let dataListParm = dataList.map(v => v.id);
        parm = dataListParm;
      }

      let routeData = this.$router.resolve({ name: 'whPriterDropShippingPickingList', query: { sendId: parm.join(',') } });
      window.open(routeData.href, '_blank');

      // this.$router.push({name:'whPrinterInGood',query:{packId:row.id},target:"_blank"});
    },

    //取消运单
    cancelAction(event, row) {
      event.stopPropagation();

      // this.$confirm(this.$t('204ffab8a6e01870')+parm.length+'条数据 取消发货 吗?', this.$t('daaaeb1b7b22b126'), {
      this.$confirm('确定 取消运单 吗?', this.$t('daaaeb1b7b22b126'), {
        //
        //
        type: 'warning'
      })
        .then(() => {
          this.postData(this.$urlConfig.WhPrtOrderLabelInfoCancel + '/' + row.id, {}, () => {
            this.initData();
            this.$message.success('取消成功！');
            // this.$router.push({name:'WhPackage',query:{open:'add'}});
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: this.$t('i18nn_2e58cb9b52e2a214')
          // });
        });
    },

    //提交打单
    submitOrderRateAction(event, row) {
      event.stopPropagation();
      // this.getPriterOrderRate(row,index,row.id);
      let parm = [];
      if (row) {
        parm = [row.id];
      } else {
        //多条
        let dataList = this.multipleSelection;
        if (dataList.length < 1) {
          this.$message.warning(this.$t('7b80e66b535a3732'));
          return;
        }
        // if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
        //   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
        //   return;
        // }

        let dataListParm = dataList.map(v => v.id);
        parm = dataListParm;
      }
      this.$confirm(this.$t('204ffab8a6e01870') + parm.length + '条数据 提交打单吗?', this.$t('daaaeb1b7b22b126'), {
        type: 'warning'
      })
        .then(() => {
          this.postData(this.$urlConfig.WhPriterOrderCreateLabel, parm, () => {
            this.initData();
            this.$message.success(this.$t('i18nn_1cb9e763fc0324fa'));
            // this.$router.push({name:'WhPackage',query:{open:'add'}});
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: this.$t('i18nn_2e58cb9b52e2a214')
          // });
        });
    },

    //面单查询
    toExSheetView(event, row, index) {
      // event.stopPropagation();
      // this.getExSheetView(row.id);
      
      event.stopPropagation();
      // let parm = [row.id];
      let parm = [];
      
      if (row) {
        //     //单条
        parm = [row.id];
      } else {
        //多条
        let dataList = this.multipleSelection;
        if (dataList.length < 1) {
          this.$message.warning(this.$t('7b80e66b535a3732'));
          return;
        }
        // if (dataList.findIndex(item => '10' != item.exprStatus) > -1) {
        //   this.$message.warning(this.$t('i18nn_7b23bac6ffe353b9'));
        //   return;
        // }
      
        let dataListParm = dataList.map(v => v.id);
        parm = dataListParm;
      }
      // this.$confirm(this.$t('204ffab8a6e01870') + parm.length + '条数据 面单查询吗?', this.$t('daaaeb1b7b22b126'), {
      //   //
      //   //
      //   type: 'warning'
      // })
      //   .then(() => {
          // this.postData(this.$urlConfig.WhPrtOrderLabelInfoRemove, parm, () => {
          //   this.initData();
          //   this.$message.success(this.$t('9f30371831a98237'));
          //   // this.$router.push({name:'WhPackage',query:{open:'add'}});
          // });
          this.getExSheetView(parm);
        // })
        // .catch(() => {
        //   // this.$message({
        //   //   type: 'info',
        //   //   message: this.$t('i18nn_2e58cb9b52e2a214')
        //   // });
        // });
    },

    //撤销打单
    revocationAction(event, row) {
      event.stopPropagation();
      // let parm = [row.id];
      let parm = [];

      if (row) {
        //     //单条
        parm = [row.id];
      } else {
        //多条
        let dataList = this.multipleSelection;
        if (dataList.length < 1) {
          this.$message.warning(this.$t('7b80e66b535a3732'));
          return;
        }
        // if (dataList.findIndex(item => '10' != item.exprStatus) > -1) {
        //   this.$message.warning(this.$t('i18nn_7b23bac6ffe353b9'));
        //   return;
        // }

        let dataListParm = dataList.map(v => v.id);
        parm = dataListParm;
      }
      this.$confirm(this.$t('204ffab8a6e01870') + parm.length + '条数据 撤销打单吗?', this.$t('daaaeb1b7b22b126'), {
        //
        //
        type: 'warning'
      })
        .then(() => {
          this.postData(this.$urlConfig.WhPrtOrderLabelInfoRemove, parm, () => {
            this.initData();
            this.$message.success(this.$t('9f30371831a98237'));
            // this.$router.push({name:'WhPackage',query:{open:'add'}});
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: this.$t('i18nn_2e58cb9b52e2a214')
          // });
        });
    },

    //编辑ServiceLevel
    editServiceLevelAction(event, row) {
			console.log(row);
			
      event.stopPropagation();

      this.dialogEditData = true;

      this.EditData = {
        id: row.id,
				carrierServiceLevel:''
      }
    },

    //提交编辑
    submitEditDataForm() {
      let parm = this.EditData;
      // this.$confirm('确定提交 录入运单 吗?', this.$t('daaaeb1b7b22b126'), {
      //     //
      //     //
      //     type: 'warning'
      //   })
      //     .then(() => {
      this.postData(this.$urlConfig.WhPrtOrderLabelServiceLevel, parm, () => {
        this.dialogEditData = false;
        this.initData();
        this.$message.success(this.$t('9f30371831a98237'));
        // this.$router.push({name:'WhPackage',query:{open:'add'}});
      });
      // })
      // .catch(() => {
      //   // this.$message({
      //   //   type: 'info',
      //   //   message: this.$t('i18nn_2e58cb9b52e2a214')
      //   // });
      // });
    },

    //下载提货单
    // downloadBillLading(event, row) {
    //   event.stopPropagation();
    //   // this.$router.push({ name: 'WhInSetOutPlace', query: { sendId: row.id } });
    // },

    //查看图片
    // openBigImg(event, imgsrc) {
    //   event.stopPropagation();
    //   console.log('openBigImg', imgsrc);
    //   this.dialogImgVisible = true;
    //   this.imgUrlBigShow = imgsrc;
    // },

    changCus(data) {
      console.log('changCus', data);
      this.filterData.userId = data.userId;
      
      this.initData();
    },
		//格式换待导出去数据
		formatterData(arr){
			arr.forEach(item=>{
				try{
					// item.labelRequest = `{\"carrier\":\"FedEx Ground CA洛杉矶始发\",\"carrierServiceLevel\":\"FedEx Home Delivery\",\"ecOrder\":\"113-4513027-2314663\",\"itemList\":[{\"quantity\":1,\"sku\":\"YY6140-BLACK/GREY\"}],\"labelSize\":0,\"orderNum\":\"1498130200060665857\",\"parcels\":[{\"height\":11.83,\"length\":32.74,\"picking\":\"[YY6140-BLACK/GREY ]\",\"weight\":46.30,\"width\":22.09}],\"shipFrom\":{\"addrType\":0,\"address1\":\"1033 N Todd Ave\",\"city\":\"azusa\",\"company\":\"\",\"country\":\"US\",\"name\":\"Fiona Han\",\"phoneNumber\":\"626-297-9956\",\"postalCode\":\"91702\",\"state\":\"CA\"},\"shipTo\":{\"addrType\":2,\"address1\":\"358 EAGLE BROOK LN\",\"address2\":\"\",\"city\":\"BUDA\",\"company\":\"\",\"country\":\"US\",\"name\":\"Alyssa Ward\",\"phoneNumber\":\"+1 210-728-4548 ext. 66091\",\"postalCode\":\"78610-2642\",\"state\":\"TX\"},\"weightUnitType\":0}`;
					item.labelRequest = item.labelRequest.replace("/\\/g","");
					item.labelRequestJson = JSON.parse(item.labelRequest);
				}catch(e){
					//TODO handle the exception
					console.log(e);
				}
			});
			return arr;
		},
		
		//分页的筛选项数据
		pageFilterData(){
			let startTime = '';
			let endTime = '';
			if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				startTime = this.filterData.daterange[0];
				endTime = this.filterData.daterange[1];
			} else {
				this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
			}
			
			return {
				// exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,
				orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
				userId: this.filterData.userId ? this.filterData.userId : null,
				keyword: this.filterData.keyword ? this.filterData.keyword : null,
				labelReady:this.filterData.labelReady ? this.filterData.labelReady : null,
				labelStatus: this.filterData.labelStatus ? this.filterData.labelStatus : null,
				whNo: this.filterData.whNo ? this.filterData.whNo : null,
				createTimeStart: startTime ? startTime : null,
				createTimeEnd: endTime ? endTime : null,
				// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			};
		},
		
    //请求分页数据
    getPageData() {
      let filterData = Object.assign({
      	offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
      	limit: this.pagination.page_size, //当前页显示数目
      },this.pageFilterData())
			
      this.loading_load = true;
      this.$http
        .put(this.$urlConfig.WhPrtOrderLabelInfoPageList, filterData)
        .then(({ data }) => {
          
          this.loading_load = false;
          if (200 == data.code) {
						let tableData = this.formatterData(data.rows);
            //表格显示数据
            this.tableData = tableData;
            //当前数据总条数
            this.pagination.total = parseInt(data.total);
            //当前页数
            // this.pagination.current_page = parseInt(data.current);
            //当前页条数
            // this.pagination.page_size = parseInt(data.size);
            // this.$message.success('商家账单列表，请求成功');
          } else {
            this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
          }
        })
        .catch(error => {
          console.log(error);
          this.$message.error(this.$t('hytxs0000030'));
          this.loading_load = false;
        });
    },

    //选择行
    handleCurrentChange(row, event, column) {
      // this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
    },
    //多选
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },

    //提交数据
    postData(url, formData, callback) {
      // let _this = this;
      this.loading = true;

      this.$http
        .put(url, formData)
        .then(({ data }) => {
          console.log(this.$t('i18nn_bc868e024b80d2e3'));
          console.log(data);
          
          this.loading = false;
          if (200 == data.code) {
            callback();
          } else {
            if (!data.msg) {
              data.msg = this.$t('dbe331ab679cd67f');
            }
            this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
              type: 'warning'
            });
          }
        })
        .catch(error => {
          console.log(error);
          console.log(this.$t('i18nn_a7d2e953195a5588'));
          this.loading = false;
          this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
            type: 'warning'
          });
        });
    },
    //查询面单
    getExSheetView(exprIdArr) {
      // let _this = this;
      // console.log(keyword);
      this.$message.success("正在查询..");
      this.loading = true;
      this.$http
        .put(this.$urlConfig.WhPrtOrderLabelInfoQuery, exprIdArr)
        .then(({ data }) => {
          console.log(this.$t('i18nn_32aff432f169204e'));
          console.log(data);
          this.loading = false;
          if (200 == data.code) {
            this.$message.success(this.$t('i18nn_34e1e57771d4bd81'));
            this.initData();
          } else {
            if (!data.msg) {
              data.msg = '查询面单失败,请重试';
            }
            this.$message.warning(data.msg);
          }
        })
        .catch(error => {
          console.log(error);
          console.log('查询面单接口，请求失败');
          this.loading = false;
          this.$message.error('查询失败,请重试!');
        });
    },
    //查询数据字典
    // getDicData() {
    //   this.$http
    //     .put(this.$urlConfig.HyDicQueryList, ['wh_fedex_carrier_service_fevel'])
    //     .then(({ data }) => {
    //       console.log('查询数据字典，请求成功');
    //       console.log(data);
    //       if (200 == data.code && data.data) {
    //         this.selectOption.wh_fedex_carrier_service_fevel = data.data['wh_fedex_carrier_service_fevel'];
    //       } else {
    //         if (!data.msg) {
    //           data.msg = this.$t('hytxs0000032');
    //         }
    //         this.$message.warning(data.msg);
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       console.log('查询数据字典接口，请求失败');
    //       this.$message.error(this.$t('hytxs0000033'));
    //     });
    // },

    //打开导入弹窗
    // openExcelDialog() {
    //   this.dialogUploadVisible = true;
    //   this.excelData = [];
    //   this.fileExcel = null;
    //   this.excelFileName = '';
    //   // this.excelFileName = "";
    //   // this.$refs.file.value = '';
    //   this.$nextTick(_ => {
    //     try {
    //       this.$refs.file.value = '';
    //     } catch (e) {
    //       console.log(e);
    //       //TODO handle the exception
    //     }
    //   });
    // },
    // fileChange(e) {
    //   // this.loading = true;
    //   console.log(e);

    //   console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
    //   this.excelData = [];
    //   this.fileExcel = null;
    //   this.excelFileName = '';
    //   if (!this.$refs.file || !this.$refs.file.files) {
    //     this.$alert(this.$t('66261f0ce9d34a49'), this.$t('daaaeb1b7b22b126'), {
    //       // //
    //     });
    //     return;
    //   }
    //   if (this.$refs.file.files[0]) {
    //     if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
    //       this.fileExcel = this.$refs.file.files[0];
    //       this.excelFileName = this.fileExcel.name;

    //     } else {
    //       this.$alert('文件不能超过10M', this.$t('daaaeb1b7b22b126'), {});
    //     }

    //   } else {

    //   }
    // },
    // openExcel() {
    //   // this.$refs.file.value = '';
    //   // this.$refs.file.dispatchEvent(new MouseEvent('click'));
    //   var comment = this.$refs.file;
    //   if (document.all) {
    //     // For IE
    //     comment.click();
    //   } else if (document.createEvent) {
    //     // 在ff中要为a标签添加click事件，并且侦听该事件
    //     var ev = document.createEvent('MouseEvents');
    //     ev.initEvent('click', false, true);
    //     comment.dispatchEvent(ev);
    //   } else {
    //     // this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
    //     this.$alert(this.$t('ea5174c2d4777af3'), this.$t('daaaeb1b7b22b126'), {
    //       // //
    //     });
    //   }
    // },
    //导入excel
    // uploadExcel() {
    //   if (!this.fileExcel) {
    //     this.$alert(this.$t('9cdac116e11d2942'), this.$t('daaaeb1b7b22b126'), {});
    //     return;
    //   }

    //   this.loadingExcel = true;

    //   let file = this.fileExcel;
    //   var formdata = new FormData();
    //   formdata.append('file', file);

    //   this.$http
    //     .post(this.$urlConfig.WhWrTrackingExRead, formdata, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data'
    //       }
    //     })
    //     .then(({ data }) => {
    //       console.log(this.$t('i18nn_bc868e024b80d2e3'));
    //       console.log(data);
    //       this.loadingExcel = false;
    //       if (200 == data.code && data.rows) {
    //         this.excelData = data.rows;

    //       } else {
    //         this.$alert(data.msg ? data.msg : this.$t('dbe331ab679cd67f'), this.$t('daaaeb1b7b22b126'), {
    //           type: 'warning'
    //           //
    //         });
    //       }

    //     })
    //     .catch(error => {
    //       console.log(error);
    //       // console.log(this.$t("tips.submitError"));
    //       this.loadingExcel = false;
    //       this.$alert(this.$t('33bae59bb784a109'), this.$t('daaaeb1b7b22b126'), {
    //         type: 'warning'
    //         //
    //       });
    //     });
    // },
    //提交-导入excel
    // submitExcelForm() {
    //   this.loadingExcel = true;
    //   this.$http
    //     .put(this.$urlConfig.WhWrTrackingExSubmit, this.excelData)
    //     .then(({ data }) => {
    //       console.log(this.$t('i18nn_bc868e024b80d2e3'));
    //       console.log(data);
    //       this.loadingExcel = false;
    //       if (200 == data.code) {
    //         // if('1'==data.data.flag){
    //         // this.excelData = data.data.data;
    //         this.dialogUploadVisible = false;
    //         this.excelData = [];
    //         this.excelFileName = '';
    //         this.fileExcel = null;

    //         this.initData();
    //         // this.$alert(this.$t('i18nn_b93f7c8c4e2346f8'), this.$t('tips.tipsTitle'), {
    //         //   type: 'success',
    //         //   //
    //         // });
    //         this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
    //         // } else {
    //         //   this.$alert(data.data.errorMsg, this.$t('daaaeb1b7b22b126'), {
    //         //     type: 'warning',
    //         //     //
    //         //   });
    //         // }
    //       } else {
    //         this.$alert(data.msg ? data.msg : this.$t('dbe331ab679cd67f'), this.$t('daaaeb1b7b22b126'), {
    //           type: 'warning'
    //           //
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       // console.log(this.$t("tips.submitError"));
    //       this.loadingExcel = false;
    //       this.$alert(this.$t('33bae59bb784a109'), this.$t('daaaeb1b7b22b126'), {
    //         type: 'warning'
    //         //
    //       });
    //     });
    // }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>

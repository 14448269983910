<template>
	<div class="">
		<!-- <div class="tableCon">
			<div class="tableConTable">
				<div class="tableConTop">
					<h3>
						<span class="tct_tit">{{ $t('i18nn_785ee8efb6d09b2a') }}</span>
					</h3>
				</div>
			</div>
		</div> -->
		<el-tabs type="border-card">
		 <el-tab-pane label="Fedex"><fedexPrinterOrder></fedexPrinterOrder></el-tab-pane>
		 <el-tab-pane label="UPS"><upsPrinterOrder></upsPrinterOrder></el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
import fedexPrinterOrder from '@/components/WarehouseCenter2/PriterOrderDetListTem/fedexPrinterOrder.vue';
import upsPrinterOrder from '@/components/WarehouseCenter2/PriterOrderDetListTem/upsPrinterOrder.vue';
export default {

	components: {
		fedexPrinterOrder,
		upsPrinterOrder
	},
	data() {
		return {};
	},
	// watch: {
	//   $route(to, from) {

	// 	}
	//   }
	// },
	activated() {},
	//创建时
	created() {},
	//编译挂载前
	mounted() {},
	methods: {}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
